
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import { HiPlus, HiUserGroup } from "react-icons/hi";
import DataGrid from '../../Components/DataGrid';
import HeaderButton from '../../Components/HeaderButton';
import { AxiosLite, CallAxios } from '../../Helpers';
import { ActionItem, ActionsBox, ContentBox, ContentSubHeader, InputField, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { CheckCircle, Gear, Invite, RemoveCircle } from '../../Components/ActionBtns';

const columns = [
    { key: 'userName', name: 'Employee Name', sortable: true, searchable: true, disableSearch: false },
    { key: 'email', name: 'Email', searchable: true, disableSearch: false },
    { key: 'phone', name: 'Phone', searchable: true, disableSearch: false },
    { key: 'isActive', name: 'Status', sortable: true, searchable: false },
    { key: 'actions', name: 'Actions' },
];

function SupportUserList() {
    const [SupportUserList, setSupportUserList] = useState([]);
    const [ShowAddEditSupportUserModal, setShowAddEditSupportUserModal] = useState(false);
    const [SelecteUserId, setSelecteUserId] = useState("");

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite('api/AdminPanel/SupportUser/GetSupportUserList|get')
        if (!!response && response.status === 200) {
            setSupportUserList(response.data)
        }
    }
    const UpdateStatus = async (id) => {
        var response = await CallAxios('api/AdminPanel/SupportUser/UpdateStatus|post', { id: id })
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const InviteUser = async (val) => {
        var response = await CallAxios('api/AdminPanel/SupportUser/InviteSupportUser|post', { UserId: val.userId, Name: val.userName, Email: val.email })
        if (!!response && response.data === true) {
            toast.success("Invitation sent...")
        }
    }

    return (
        <>
            <ContentSubHeader>
                <SubHeaderWrapper>
                    <SubHeaderTitleBox>
                        <HiUserGroup color="rgb(1, 219, 169)" size={22} />
                        <SubHeadTitle>Support Users</SubHeadTitle>
                    </SubHeaderTitleBox>
                    <ActionsBox>
                        <ActionItem>
                            <HeaderButton title="Add" onClick={(e) => { setSelecteUserId(""); setShowAddEditSupportUserModal(true) }} icon={<HiPlus color='#fff' size={18} />} />
                        </ActionItem>
                    </ActionsBox>
                </SubHeaderWrapper>
            </ContentSubHeader>
            <ContentBox>
                <Container fluid>
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="p-4">
                                <Row>
                                    <DataGrid
                                        columns={columns}
                                        rows={SupportUserList}

                                        customBody={(rows) =>
                                            rows?.map((val, i) =>
                                                <tr>
                                                    <td>{val.userName}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.phone}</td>
                                                    <td>
                                                        {
                                                            val.isActive ?
                                                                <CheckCircle color="#00dba9" data-tooltip-id="my-tooltip" data-tooltip-content="Active" onClick={() => { UpdateStatus(val.userId) }} />
                                                                :
                                                                <RemoveCircle color="#f25656" data-tooltip-id="my-tooltip" data-tooltip-content="Inactive" onClick={() => { UpdateStatus(val.userId) }} />
                                                        }
                                                    </td>
                                                    <td>
                                                        {!val.isAccountCreated &&

                                                            <Invite color='#0d7be3' data-tooltip-id="my-tooltip" data-tooltip-content="Invite" onClick={() => { InviteUser(val); }} />
                                                        }
                                                        <Gear color='#0d7be3' data-tooltip-id="my-tooltip" data-tooltip-content="Setting" onClick={() => { setSelecteUserId(val.userId); setShowAddEditSupportUserModal(true) }} />
                                                    </td>
                                                </tr>
                                            )}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </ContentBox>
            {
                ShowAddEditSupportUserModal &&
                <SupportUserModal show={ShowAddEditSupportUserModal} onHide={setShowAddEditSupportUserModal} getData={getData} SelecteUserId={SelecteUserId} setSelecteUserId={setSelecteUserId} />
            }
        </>
    );
}

export default SupportUserList;

function SupportUserModal(props) {

    useEffect(() => {
        if (!!props.SelecteUserId) {
            GetUserDetail()
        }
    }, [props.SelecteUserId]);

    const formik = useFormik({
        initialValues: {
            UserId: props.SelecteUserId,
            name: '',
            email: '',
            abbreviation: '',
            phone: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            name: Yup.string().required("Required")
        }),
        onSubmit: values => {
            createUser(values)
        },
    });

    const GetUserDetail = async () => {
        var response = await CallAxios("/api/AdminPanel/SupportUser/GetSupportUserById|post", { id: props.SelecteUserId })
        if (!!response && response.status === 200) {
            formik.setFieldValue("name", response.data.name)
            formik.setFieldValue("email", response.data.email)
            formik.setFieldValue("phone", response.data.phone)
            formik.setFieldValue("abbreviation", response.data.abbreviation)
        }
    }
    const createUser = async (data) => {
        var response = await CallAxios("/api/AdminPanel/SupportUser/AddEditSupportUser|post", data)
        if (!!response && response.status === 200) {
            if (response.data === "EmailExist") {
                toast.error('Email already exist.')
            } else {
                props.getData();
                props.setSelecteUserId("");
                props.onHide(false)
            }
        }
    }

    return (
        <Modal size='lg' show={props.show} onHide={() => { props.setSelecteUserId(""); props.onHide(false) }}>
            <Modal.Header closeVariant='red' closeButton>
                <Modal.Title>{!!props.SelecteUserId ? "Edit " : "Add "} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <FormGroup className="position-relative mb-3">
                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <InputField border="2" type="text" placeholder="Employee Name" name="name" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} />
                            {formik.touched.name && formik.errors.name ? (
                                <span className="error text-danger">{formik.errors.name}</span>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="position-relative">
                            <Form.Label className='fw-bold' >Abbreviation</Form.Label>
                            <InputField border="2" type="text" placeholder="e.g. MM" name="abbreviation" value={formik.values.abbreviation} onChange={formik.handleChange} className="ThickBorder my-0 " />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="position-relative">
                            <Form.Label className='fw-bold' >Email</Form.Label>
                            <InputField border="2" type="text" placeholder="e.g. abc@xyz.com" name="email" value={formik.values.email} onChange={formik.handleChange} className="ThickBorder my-0 " />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="error text-danger">{formik.errors.email}</span>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="position-relative">
                            <Form.Label className='fw-bold' >Phone</Form.Label>
                            <InputField border="2" type="text" name="phone" value={formik.values.phone} onChange={formik.handleChange} className="ThickBorder my-0 " />
                        </FormGroup>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={(e) => formik.handleSubmit()}>Done</Button>
            </Modal.Footer>
        </Modal>
    )
}