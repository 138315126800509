import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { Constants } from "../Contents";


export const MainWrapper = styled.div`
margin-left:30px;
margin-right:15px;
padding:20px 0px;
`
export const ContentArea = styled.div`
background-color:#f4f4f4;
// border-radius:50px;
padding-bottom:15px;
`
export const ContentHeader = styled.div`
background-color:#fff;
// border-radius:50px 50px 0px 0px;
padding:20px;
`
export const ContentSubHeader = styled.div`
padding-top:10px;
padding-left: 20px;
padding-right: 20px;
`
export const GLobalcss = createGlobalStyle`
.App , body {
    background-color:${props => props.theme.colors.bg};
}
.modal-header {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}
.modal-footer{
  justify-content:flex-start;
  border-top:none;
}
.modal-footer button , .themeGreenBtn , .btn-primary,.btnPrimaryGreen{
  background-color:${props => props.theme.colors.buttons};
  border-color:${props => props.theme.colors.buttons};

}
.modal-footer button:hover , .themeGreenBtn:hover , .btnPrimaryGreen:hover , .btn-primary:hover , .modal-footer button.btn:active , .themeGreenBtn:active , .btn-primary:active , .btnPrimaryGreen:active{
  background-color:${props => props.theme.colors.buttons};
  border-color:${props => props.theme.colors.buttons};
}
.modal-header .modal-title , .AddComponentLink{
  color:${props => props.theme.colors.headerText};
}
.AddComponentLink{
  color:${props => props.theme.colors.headerText};
  text-decoration:none;
  cursor:pointer;
}
.LinkColorRed{
  color:#f25656;
  text-decoration:none;
  cursor:pointer;
}
.form-check-input:checked {
  background-color: ${props => props.theme.colors.buttons};
  border-color: ${props => props.theme.colors.buttons};
}
.form-check-input{
  border-color: ${props => props.theme.colors.buttons};
}
.form-check-input:focus{
  box-shadow: 0 0 0 0.25rem ${props => props.theme.colors.buttons}40
}
.form-label{
  font-weight:600;
  font-size:14px;
}
.ToolMonthChange{
  display:flex;
  align-items:center;
}
.ToolMonthChange .MonthChangeContainer ,.ToolMonthChange  svg {
  font-size: 20px;
  margin-right:10px;
}
.modal-dialog{
  max-width : 800px !important; 
}
.swal2-container{
  z-index: 11000;

}
a{
  color:#000;
  text-decoration:none;
}
a:hover{
  color:#ccc;
  text-decoration:none;
}
.react-tooltip{
  z-index:9999999;
}
.DataListGroup .headerColor{
  background :${props => props.theme.colors.bg} ;
}
.DataListGroup .addGreyInOdd:nth-of-type(odd){
  background-color:#f1f1f1;   
}
.DataListGroup .darkGreen , .bg-success{
  background-color:${props => props.theme.colors.bg} !important;   
  border-color:${props => props.theme.colors.bg};
}
.ActiveRad {
  color: ${props => props.theme.colors.active} !important;   
}
.CustomFullCalendarWrapper tbody{
  background:#fff;
}
.ListActionIcon{
  opacity:0;
}
.addGreyInOdd:hover .ListActionIcon{
  opacity:1;
}
.nav-tabs.nav-underline .nav-link.active , .nav-tabs.nav-underline .nav-link{
  border-left:0;
  border-right:0;
  border-top:0;

}
.nav-tabs.nav-underline .nav-link.active {
color:${props => props.theme.colors.active};
border-color:${props => props.theme.colors.active};

}
.nav-tabs.nav-underline .nav-link {
color:#909090;

}
.bgGrey {
  background-color:#ccc;
}
.price-list{
  list-style-type:none;
}
.SimpleColorPicker{
  display:block;
}
.SimpleColorPicker .color{
  width: 25px;
  height: 30px;
  display: inline-block;
  cursor:pointer;
}
.SimpleColorPicker .color.selected {
border:2px solid red;
}
.btnShowEmoji {
  right: 10px;
position: absolute;
top: 5px;
z-index:99;
}
 .pickerWrapperEmj{
  right: 10px;
position: absolute;
top: 30px;
z-index:9999;
}
.menu-item-box{
  position:relative
}
.menu-item-box .badge{
  margin-left: 10px;
  padding: 6px;
  border-radius: 50px;
  position: absolute;
  right: 15px;
}
.react-contextmenu-wrapper{
  display:inline-block;
}
.react-contextmenu.react-contextmenu--visible{
  background: white;
border: 1px solid #ccc;
padding:0px;
}
.react-contextmenu-item{

  padding: 5px 10px;
  cursor:pointer;

}
.noWrap{
  white-space:nowrap;
}
.react-contextmenu-item:hover{

  background:#eee;

}
.react-contextmenu-item:not(:last-child){
  border-bottom:1px solid #ccc;
}

.white-space-nowrap , thead th , tbody tr td:not(.eventContainer){
  white-space: nowrap;

}
`
export const DisplayFlex = styled.div`
display:flex;
align-items:center;
`
export const DisplayBetween = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
export const HeaderLeftLabel = styled.div`
font-size:16px;
font-weight:500;
color:${props => props.theme.colors.bg};
`
export const HeaderMenu = styled.div`
display:flex;
align-items:center;
`
export const HeaderMenuItem = styled(Link)`
border-left: 1px solid #d8d8d8;
padding:0px 12px;
cursor:pointer;
`
export const IconWrapper = styled.div`
position:relative;
`

export const CalendarBadge = styled.span`
border-radius: .25rem;
display: inline-block;
font-size: 75%;
font-weight: 700;
line-height: 1;
padding: .25em .4em;
text-align: center;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
vertical-align: initial;
`

export const Badge = styled.span`
position:absolute;
background-color:#e74c3c;
color:#fff;
border-radius:50%;
font-size:10px;
top:${props => props.NotifBadge ? '-7px' : '-5px'};
right:${props => props.NotifBadge ? '3px' : '-5px'};
padding: 0px 4px;
font-weight:500;
z-index:2;
`
export const RotateIcon = styled.div`
transform: rotate(320deg);
`
export const AvatarBox = styled.div`
display:inline-block;
margin-right:5px;
border-radius:50%;
height:30px;
width:30px;
font-size:14px;
line-height:26px;
color:${props => props.theme.colors.bg};
font-weight:600;
text-align:center;
background-image:${props => props.bgImage ? props.bgImage : null};
background-size:${props => props.bgImage ? "contain" : null};
background-postion:${props => props.bgImage ? "center" : null};
background-color:${props => props.bgImage ? null : '#f4f4f4'};
`
export const SubHeaderWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
export const SubHeaderTitleBox = styled.div`
display:flex;
align-items:center;
`
export const SubHeadTitle = styled.div`
font-size:20px;
font-weight:700;
color:${props => props.theme.colors.headerText};
margin-left:5px;
`
export const ActionsBox = styled.div`
display:flex;
align-items:center;
`
export const ActionItem = styled.div`
margin-left:7px;
`
export const OptionBox = styled.div`
display:flex;
align-items:center;
`
export const OptionText = styled.div`
font-size:14px;
margin-left:7px;
`
export const ChooseAll = styled.div`
border:1px solid ${props => props.theme.colors.buttons} !important;
border-radius:7px;
text-align:center;
padding:3px;
cursor:pointer;
&:hover{
background-color:${props => props.theme.colors.buttons};
color:#fff;
}
`
export const ShowItem = styled.div`
background-color:${props => props.theme.colors.buttons};
color:#fff;
cursor:pointer;
border:1px solid ${props => props.theme.colors.buttons} !important;
border-radius:7px;
text-align:center;
padding:3px;
`
export const ContentBox = styled.div`
padding-top:15px;
padding-left:20px;
padding-right:20px;
`
export const LoginWrapper = styled.div`
width:100%;
height:100Vh;
display:flex;
flex-direction:column;
background-image:url('/assets/loginBackground.jpg');
background-size: cover;
background-repeat:no-repeat;
background-position:top;
justify-content: center;
`
export const LoginContent = styled.div`
padding-left:50px;
width:35%;
`
export const LoginLogo = styled.img`
width:200px;
height:auto;
object-fit:contain;
margin-bottom:25px;
`
export const TitleWrapper = styled.div`
margin-bottom:30px;
`
export const SubTitle = styled.div`
font-size:25px;
font-weight:500;
line-height:22px;
`
export const Title = styled.div`
font-size:50px;
font-weight:800;
line-height:40px;
margin:10px 0px;
`
export const FormGroup = styled.div`
margin-bottom:15px;
position:relative;
`
export const InputField = styled.input.attrs(props => ({ type: !!props.type ? props.type : 'text' }))`
  width: 100%;
  border: ${props => props.border === '2' ? `2px solid ${props => props.theme.colors.buttons}` : `1px solid ${props => props.theme.colors.buttons}`};
  border-radius: 7px;
  outline: none;
  padding: 10px 15px;
  box-sizing: border-box;
  transition: 0.3s;
  &:focus + .input-icon svg{
color:${props => props.theme.colors.buttons} !important;
  }
`
export const InputTypeFileWrapper = styled.div`
& input {
  display: block;
  width: 100%;
  padding: 0.2rem 0.75rem ;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  overflow: hidden;

}
`
export const InputIcon = styled.div`
position:absolute;
right:10px;
top: 8px;
`
export const DisplayFlexBetween = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
export const AnchorLink = styled.div`
color:${props => props.theme.colors.buttons};
font-size:14px;
cursor:pointer;
&:hover{
  text-decoration:underline
}
`
export const FormCheck = styled.div`
display: flex;
align-items: center;
`
export const FormCheckLabel = styled.div`
font-size:14px;
font-weight:500;
margin-left:${props => props.size === "sm" ? '5px' : '10px'};
`
export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
width: ${props => props.size === "sm" ? '1.1rem' : '1.5rem'};
height: ${props => props.size === "sm" ? '1.1rem' : '1.5rem'};
margin-top: -0.125rem;
vertical-align: top;
background-color:#fff;
border: 1px solid ${props => props.theme.colors.buttons};
border-radius:5px;
appearance: none;
print-color-adjust: exact;
pointer-events:${props => props.disablePointerEvent ? 'none' : 'auto'};
&:checked{
background-image: url('/assets/check-mark.png');
background-color:${props => props.theme.colors.buttons} !important;
background-repeat: no-repeat;
background-position: center;
background-size: ${props => props.size === "sm" ? '12px' : '16px'};
}
`
export const ButtonWrapper = styled.div`
margin-top:30px;
`
export const PrimaryButton = styled.button`
background-color:${props => props.theme.colors.buttons};
padding:10px 20px;
font-size:16px;
font-weight:600;
color:#fff;
border: 1px solid ${props => props.theme.colors.buttons};
border-radius: 20px;
`
export const ButtonBox = styled.div`
display:flex;
align-items:center;
padding:4px 10px;
border:${props => props.Light ? '1px solid #c3c5d1' : `1px solid ${props => props.theme.colors.buttons}`};
border-radius:7px;
background-color:${props => props.Light ? '#fff' : props => props.theme.colors.buttons};
cursor:pointer;
`
export const HeaderButtonText = styled.div`
font-size:13px;
color:${props => props.Light ? '#00a781' : '#fff'};
`
export const HeaderButtonIcon = styled.div`
display:flex;
margin-right:5px;
`
export const Navcontainer = styled.div`
background: rgb(8, 7, 61);
padding: 6px 20px 10px;
height: 63px;
border-bottom: 1px solid black;
`
export const Navbox = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-align: center;
-webkit-box-pack: justify;
justify-content: ${(props) => props.centerLogo ? "center" : "space-between"};
align-items: center;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
export const Logocontainer = styled.div`
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
export const Logobox = styled.div`
text-align: center;
`
export const Logo = styled.img`
width: 200px;
height: auto;
cursor: pointer;
margin-bottom: 0px;
`
export const Menucontainer = styled.div`
    width: 33.3333%;
    display: block;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media (min-width:1800px){
        width: 25%;
    }
`
export const Menubox = styled.div`
box-sizing: border-box;
    width: 100%;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    -webkit-box-pack: start;
    justify-content: flex-start;
`
export const Menulist = styled.div`
width: 100%;
box-sizing: border-box;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
export const MenuItem = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: space-between;
padding: 12px 5px;
cursor: pointer;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
export const Menulink = styled.div`
color: rgb(255, 255, 255);
border-width: 3px;
border-style: solid;
${props => props.className === `active` ? `
border-image: 
  linear-gradient(
    to right, #E80A89 0%, #F15B2A 90%
  ) 0 0 100% 0;

  ` : ``}
transition: all 0.16s ease-in-out 0s;
padding-bottom: 2px;
border-color: transparent;
font-weight: bold;
text-shadow: rgb(255 255 255 / 50%) 0px 0px 1px;
`
export const Headrightcontainer = styled.div`
width: 45%;
display: block;
box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media (min-width: 1800px){
        width: 35%;
    }
`
export const Headrightbox = styled.div`
margin: 0px 15px 0px 0px;
    padding: 0px 10px;
    height: 50px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
`
export const Headright = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
justify-content:space-between;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
export const Rightmenubox = styled.div`
display: block;
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
export const Rightmenu = styled.div`
box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
`
export const Rightmenuitem = styled.div`
padding: 6px 11px;
cursor: pointer;
color: rgb(255, 255, 255);
font-weight: 500;
background-color: rgb(11, 33, 90);
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
`
export const Headerbtnbox = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-pack: end;
justify-content: flex-end;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
export const Headerbtn = styled.div`
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
export const Closebtnbox = styled.div`
display: flex;
align-items: center;
`
export const AccountPopupwrapper = styled.div`
position: relative;
cursor: pointer;
&:hover .account-popup {
    display: block !important;
  }
`

export const Popupsection = styled.div`
background: rgb(255, 255, 255);
    cursor: default;
    position: absolute;
    z-index: 9999;
    margin-top:5px;
    padding: 10px 10px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 25%) 1px 1px 5px 1px;
    width: 250px;
    text-align: left;
    display:none;
`

export const Popupitem = styled.p`
font-size:12px;
margin-bottom:0px;
color: #9d9d9d;
text-decoration: none;
line-height: 13px;
font-weight: 600;
&:hover {
  }
`
export const Grayicon = styled.span`
color:#BBB !important;
`
export const InviteText = styled.div`
font-size:14px;
color:${props => props.theme.colors.bg};
margin-right:10px;
`
export const InviteCount = styled.div`
padding:0px 7px;
border-radius:50%;
background-color:${props => props.theme.colors.bg};
color:#fff;
font-size:14px;
text-align:center;
`
export const HeaderRadio = styled.input.attrs({ type: 'radio' })`
width: 29.5px;
height: 29px;
vertical-align: top;
background-color:#fff;
border: 1px solid ${props => props.theme.colors.buttons};
border-radius:50%;
appearance: none;
print-color-adjust: exact;
cursor:pointer;
&:checked{
background-image: url('/assets/check.png');
background-color:#fff !important;
background-repeat: no-repeat;
background-position: center;
background-size: 18px;
}
`

export const TableWrapper = styled.div`
& .firsthead th{
    background-color:${props => props.theme.colors.bg} !important;
    color:#fff;
    font-size:14px;
    font-weight:600;
    border-color:transparent;
}
& td{
  border-color:transparent;
}
& .table>:not(caption)>*>* {
    background-color:#fff;
}
& .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color:#f1f1f1;   
}
& .page-item.active a{
  background-color:${props => props.theme.colors.bg};
  color:#fff;
}
& .page-link{
  color:${props => props.theme.colors.bg};
}
& .page-link:focus{
  box-shadow:none !important;
}
`
export const BSelectWrapper = styled.div`
& .CustomBSELECT{
    background-color: transparent;
    border:none;
}

`
export const RequiredText = styled.div`
font-size:13px;
color: #d32f2f;
font-weight:400;
padding-left:15px;
`

export const DOT = styled.span`
width:16px;
height:16px;
background-color:${props => !!props.color ? props.color : "#ccc"};
border-radius:50%;
display: inline-block;
margin-right:10px;
`


export const CircleBtnWithIcon = styled.div`
display:inline-flex;
background-color:transparent;
width: 30px;
height: 30px;
margin-right: 10px;
justify-content: center;
align-items: center;
border-radius: 50px;
font-size: ${props => !!props.fs ? props.fs : "22px"};
color:${props => !!props.color ? props.color : "#000"};
cursor:pointer;
&.disabled{
  opacity: .5;
}
& .onHover{
  display:none;
}
&:hover:not(.Btn) svg:first-child{
  display:none;
}
&:hover:not(.Btn) .onHover{
  display:block;
}
`

export const StatusBox = styled.div`
background-color: ${props => props.status === Constants.absenceStatus.Confirmed ?  props.theme.colors.buttons : props.status === Constants.absenceStatus.Pending ? "#e1ab1d" : "#f34d4d"};
border: 1px solid ${props => props.status === Constants.absenceStatus.Confirmed ?  props.theme.colors.buttons : props.status === Constants.absenceStatus.Pending ? "#e1ab1d" : "#f34d4d"};
padding:5px 10px;
color:#fff;
border-radius:8px;
font-size:13px;
text-align:center;
`

export const ActiveInactiveStatusBox = styled.div`
background-color: ${props => props.status === true ? "#27ae60" : "#f39c12"};
border: 1px solid ${props => props.status === true ? "#27ae60" : "#f39c12"};
padding:5px 10px;
color:#fff;
border-radius:8px;
font-size:13px;
text-align:center;
`

export const TabsItems = styled.div`
    font-size:14px;
    cursor:pointer;
    & .active{
        background-color:${props => props.theme.colors.bg};
        color:#fff;
    border-radius:5px;
        
    }
`

export const GreenUserName = styled.div`
color:${props => props.theme.colors.headerText};
font-size: 25px;
    font-weight: 600;
    margin-bottom:10px;
`

export const DetailsGreyBox = styled.div`
background-color:#ececec;
padding:8px;
display:inline-block;
margin-right:7px;
margin-bottom:5px;
border-radius:5px;
`

export const CollapseMenu=styled.div`
background-color: ${props => props.theme.colors.active};
border-radius: 3px;
color: #fff !important;
display:inline-block;
padding-bottom:4px;
padding-left:5px;
padding-right:5px;
border-radius:5px;
cursor:pointer;
`

export const DisplayStart = styled.div`
display:flex;
align-items:center;
justify-content:start;
`