import { useNavigate, useParams } from "react-router-dom";
import { CallAxios } from "../../Helpers";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import DataGrid from "../../Components/DataGrid";


const columns = [
    { key: 'locationname', name: 'Location Name', sortable: true, searchable: true, disableSearch: false },
    { key: 'noOfEmployees', name: 'Employees', searchable: false, disableSearch: false },
    { key: 'actions', name: 'Actions' },
];

function Locations() {
    const [LocationsList, setLocationsList] = useState([]);
    let navigate = useNavigate();
    const { companyid } = useParams();
    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await CallAxios("api/AdminPanel/Location/GetLocationsList|post", { id: companyid })
        setLocationsList(response.data)
    }
    return (
        <>
            <Container fluid>
                <Col md={12} className="p-4">
                    <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>
                <Col md={12} className="p-4">
                    <Row>
                        <Col md={12} className="mb-3 ">
                            <Row>
                                <DataGrid
                                    columns={columns}
                                    rows={LocationsList}

                                    customBody={(rows) =>
                                        rows?.map((value, i) =>
                                            <tr>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.locationname}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.noOfEmployees} {value.noOfEmployees === '1' ? "employee" : "employees"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a onClick={() => { navigate("/company/" + companyid + "/locations/" + value.locationId + "/employees"); }} href="javascript:;" className='btn m-1 btn-primary btn-sm'>Employees</a>
                                                </td>
                                            </tr>
                                        )}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>
    )
}
export default Locations;