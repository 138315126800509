import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CallAxios } from "../../Helpers";
import { Button, Col, Container, Row } from "react-bootstrap";
import DataGrid from "../../Components/DataGrid";

const columns = [
    { key: 'surname', name: 'Name', sortable: true, searchable: true, disableSearch: false },
    { key: 'email', name: 'Email', searchable: true, disableSearch: false },
    { key: 'timeTrackingMode', name: 'Tracking Mode', searchable: true, sortable: true, disableSearch: false },
    // { key: 'actions', name: 'Actions' },
];


function Employees(props) {
    const [EmployeesList, setEmployees] = useState([]);
    let navigate = useNavigate();
    const { locationid } = useParams();
    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await CallAxios("api/AdminPanel/Employee/GetEmployeeList|post", { id: locationid })
        setEmployees(response.data)
    }
    return (
        <>
            <Container fluid>
                <Col md={12} className="p-4">
                    <Button onClick={() => navigate(-1)}>Back</Button>
                </Col>
                <Col md={12} className="p-4">
                    <Row>
                        <Col md={12} className="mb-3 ">
                            <Row>
                                <DataGrid
                                    columns={columns}
                                    rows={EmployeesList}

                                    customBody={(rows) =>
                                        rows?.map((value, i) =>
                                            <tr>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.surname}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.timeTrackingMode}
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <a onClick={()=>{navigate("/Locations/"+value.locationId);setCookie('locationId',value.locationId,{path:'/'})}} href="javascript:;" className='btn m-1 btn-secondary btn-sm'>Login as Admin</a>
                                                </td> */}
                                            </tr>
                                        )}
                                />

                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>
    )
}
export default Employees;