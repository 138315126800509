import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { HiLocationMarker, HiOutlineLocationMarker } from 'react-icons/hi';
import { IoCloseCircleOutline, IoSettingsSharp } from 'react-icons/io5';
import { RiAdminFill, RiAdminLine } from 'react-icons/ri';
import { TbListDetails } from 'react-icons/tb';
import { VscVmActive } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RemoveCircle } from '../../Components/ActionBtns';
import DataGrid from '../../Components/DataGrid';
import { AxiosLite, CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { CircleBtnWithIcon } from '../../Helpers/Global/StyledComponents';
import { Constants } from '../../Helpers/Contents';

const columns = [
    { key: 'companyName', name: 'Company Name', sortable: true, searchable: true, disableSearch: false },
    { key: 'invoiceEmail', name: 'Email', sortable: true, searchable: true, disableSearch: false },
    { key: 'phonNumber', name: 'Phone', searchable: true, disableSearch: false },
    { key: 'city', name: 'City', sortable: true, searchable: true, disableSearch: false },
    // { key: 'language', name: 'Language', sortable: true, searchable: true, disableSearch: false },
    { key: 'subscriptionName', name: 'Subscription', sortable: true, searchable: true, disableSearch: false },
    { key: 'period', name: 'Period', sortable: true, searchable: true, disableSearch: false },
    { key: 'isActive', name: 'Status', sortable: true, searchable: false },
    { key: 'actions', name: 'Actions' },
];

function CompaniesList() {
    const [companyList, setcompanyList] = useState([]);
    const [ShowDetailModel, setShowDetailModel] = useState(false);
    const [IdforDetail, setIdforDetail] = useState(null);
    const [ShowSubscriptionDetailModal, setShowSubscriptionDetailModal] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite('api/AdminPanel/Company/GetCompanyList|get')
        if (!!response && response.status === 200) {
            setcompanyList(response.data)
        }
    }
    const UpdateStatus = async (id) => {
        var response = await CallAxios('api/AdminPanel/Company/UpdateCompanyStatus|post', { id: id.toString() })
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const fnDetailModel = (val, id) => {
        setIdforDetail(id)
        setShowDetailModel(val)
    }
    const fnLoginasAdmin = async (id) => {
        var response = await CallAxios('api/AdminPanel/Company/LoginAsCompanyAdminById|post', { id: id.toString() })
        if (!!response && response.status === 200) {
            window.open(config.WEB_URL + '/adminlogin?code=' + encodeURIComponent(response.data), '_blank');
        }
    }

    return (
        <>
            {
                ShowDetailModel === true ?
                    <DetailModel show={ShowDetailModel} IdforDetail={IdforDetail} setShowDetailModel={setShowDetailModel} />
                    : null
            }
            {
                ShowSubscriptionDetailModal === true ?
                    <SubscriptionDetailModal show={ShowSubscriptionDetailModal} IdforDetail={IdforDetail} setShowDetailModel={setShowSubscriptionDetailModal} />
                    : null
            }
            <Container fluid>
                <Col md={12}>
                    <Row>
                        <Col md={12} className="p-4">
                            <Row>
                                <DataGrid
                                    columns={columns}
                                    rows={companyList}

                                    customBody={(rows) =>
                                        rows?.map((val, i) =>
                                            <tr>
                                                <td>{val.companyName}</td>
                                                <td>{val.invoiceEmail}</td>
                                                <td>{val.phonNumber}</td>
                                                <td>{val.city}</td>
                                                {/* <td>{val.language}</td> */}
                                                {/* <td>{val.numberOfEmployees}</td> */}
                                                <td>{val.subscriptionName}</td>
                                                <td>{val.period}</td>
                                                <td>{val.isActive == false ? <IoCloseCircleOutline size="2em" /> : <VscVmActive size="2em" />}</td>
                                                <td>
                                                    <CircleBtnWithIcon color="#7C9D96" data-tooltip-id="my-tooltip" data-tooltip-content="Edit Subscription" onClick={() => { setIdforDetail(val.companyId); setShowSubscriptionDetailModal(true) }}> <IoSettingsSharp /> <IoSettingsSharp className='onHover' /></CircleBtnWithIcon>
                                                    {
                                                        val.isSupportAccessBlock === false &&
                                                        <CircleBtnWithIcon data-tooltip-id="my-tooltip" data-tooltip-content="Login as Admin" color='#6528F7' onClick={() => fnLoginasAdmin(val.companyId)}  ><RiAdminLine /> <RiAdminFill className='onHover' /></CircleBtnWithIcon>
                                                    }

                                                    <CircleBtnWithIcon color="#7C9D96" data-tooltip-id="my-tooltip" data-tooltip-content="Locations" onClick={() => { navigate("/company/" + val.companyId + "/locations") }}> <HiOutlineLocationMarker /> <HiLocationMarker className='onHover' /></CircleBtnWithIcon>
                                                    <CircleBtnWithIcon color='#F266AB' data-tooltip-id="my-tooltip" data-tooltip-content="Detail" onClick={() => fnDetailModel(true, val.companyId)} className='Btn'><TbListDetails /></CircleBtnWithIcon>
                                                    {val.isActive == false ?
                                                        <CircleBtnWithIcon color='#00DFA2' data-tooltip-id="my-tooltip" data-tooltip-content="Active" onClick={() => UpdateStatus(val.companyId)}><AiOutlineCheckCircle /> <AiFillCheckCircle className='onHover' /></CircleBtnWithIcon>
                                                        : <RemoveCircle color='#f25656' data-tooltip-id="my-tooltip" data-tooltip-content="Deactive" onClick={() => {
                                                            Swal.fire({
                                                                title: 'Do you want to deactive location ?',
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Save',
                                                                denyButtonText: `Don't save`,
                                                            }).then((result) => {
                                                                /* Read more about isConfirmed, isDenied below */
                                                                if (result.isConfirmed) {
                                                                    UpdateStatus(val.companyId)
                                                                    Swal.fire('Saved!', '', 'success')
                                                                }
                                                            })
                                                        }} />}

                                                </td>
                                            </tr>
                                        )}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col >
            </Container >
        </>
    );
}

export default CompaniesList;


function SubscriptionDetailModal(props) {
    const [StartDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [Numberofemployees, setNumberofemployees] = useState(2);
    const [InvoiceId, setInvoiceId] = useState("");

    useEffect(() => {
        getData()
    }, [props.IdforDetail]);
    const getData = async () => {
        var response = await CallAxios('api/SubscriptionPlan/getCompanySubscriptionDetail|post', { id: props.IdforDetail.toString() })
        if (!!response && response.status === 200) {
            setStartDate(response.data.data.subscriptionStartDate)
            setEndDate(response.data.data.subscriptionEndDate)
            setNumberofemployees(response.data.data.numberOfEmployee)
            setInvoiceId(response.data.data.invoiceId)
        }
    }
    const save = async () => {
        var data = {
            InvoiceId: InvoiceId.toString(),
            EndDate: endDate,
            NoOfEmp: parseInt(Numberofemployees)
        }
        debugger
        var response = await CallAxios('api/SubscriptionPlan/UpdateSubscriptionDetail|post', data)
        if (!!response && response.data.success === true) {
            props.setShowDetailModel(false)
        }
    }
    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShowDetailModel(false)}>
                <Modal.Header closeButton className="position-relative absoluteModalClose">
                    <Modal.Title>Subscription Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold mx-3'>Start Date</Form.Label>
                                    <DatePicker className="form-control" name="beginning" disabled onChange={(date) => setStartDate(date)} selected={!!StartDate ? new Date(StartDate) : new Date()} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold mx-3'>End Date</Form.Label>
                                    <DatePicker className="form-control" name="beginning" onChange={(date) => setEndDate(date)} selected={!!endDate ? new Date(endDate) : new Date()} />
                                </FormGroup>
                            </Col>
                            {/* <Col md={3}>
                                <Form.Group className="position-relative">
                                    <Form.Label className='fw-bold mx-3'>Number of employees</Form.Label>
                                </Form.Group>
                            </Col> */}
                            <Col md={5}>
                                <Form.Group className="position-relative mx-3">
                                    <Form.Label className='fw-bold'>Number of employees</Form.Label>
                                    <Form.Select as="select" className="form-control SelectbgGrey" name='NumberOfEmployees' value={Numberofemployees} onChange={(e) => setNumberofemployees(e.target.value)} custom>
                                        {
                                            Constants.noOfEmployeeDropDown.map((val, key) => {
                                                return <option key={"noOfEmployee" + key} value={val.value}>{val.text}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={(e) => props.setShowDetailModel(false)}>Close</Button>
                    <Button className="btn btn-secondary" onClick={(e) => save()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function DetailModel(props) {
    const [CompanyDetail, setCompanyDetail] = useState({});
    const [key, setKey] = useState('');

    useEffect(() => {
        if (!!props.IdforDetail) {
            getData()
        }
    }, [props.IdforDetail]);
    const getData = async () => {
        var response = await CallAxios('api/AdminPanel/Company/GetCompanyById|post', { id: props.IdforDetail.toString() })
        if (!!response && response.status === 200) {
            setCompanyDetail(response.data)
        }
    }
    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShowDetailModel(false)}>
                <Modal.Header closeButton className="position-relative absoluteModalClose">
                    <Modal.Title>Company Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className='nav-underline' defaultActiveKey="Comapny" transition={false} id="noanim-tab-example">
                        <Tab eventKey="Comapny" title="Comapny Detail">
                            {
                                !!CompanyDetail &&
                                <Row>
                                    <Col md={12}>
                                        <b>Name:</b> {CompanyDetail.companyName}
                                    </Col>
                                    <Col md={12}>
                                        <b>Bussiness Type:</b> {CompanyDetail.chosenBranch}
                                    </Col>
                                    <Col md={12}>
                                        <b>Post code:</b> {CompanyDetail.postcode}
                                    </Col>
                                    <Col md={12}>
                                        <b>Phone:</b> {CompanyDetail.phonNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>Language:</b> {CompanyDetail.language}
                                    </Col>
                                    <Col md={12}>
                                        <b>City:</b> {CompanyDetail.city}
                                    </Col>
                                    <Col md={12}>
                                        <b>Country:</b> {CompanyDetail.country}
                                    </Col>
                                    <Col md={12}>
                                        <b>Address:</b> {CompanyDetail.address}
                                    </Col>
                                    <Col md={12}>
                                        <b>Created Date:</b> {moment(CompanyDetail.createdDate).format('MM/DD/YYYY')}
                                    </Col>
                                </Row>
                            }
                        </Tab>
                        <Tab eventKey="Subscription" title="Subscription Detail">
                            {
                                !!CompanyDetail &&
                                <Row>
                                    <Col md={12}>
                                        <b>Invoice Number:</b> {CompanyDetail.invoiceNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>Subscription Name:</b> {CompanyDetail.subscriptionName}
                                    </Col>
                                    <Col md={12}>
                                        <b>Number Of Employee:</b> {CompanyDetail.numberOfEmployee}
                                    </Col>
                                    <Col md={12}>
                                        <b>Period:</b> {CompanyDetail.subscriptionPeriod}
                                    </Col>
                                    <Col md={12}>
                                        <b>Subscription Amount:</b> {CompanyDetail.subscriptionAmount}
                                    </Col>
                                    <Col md={12}>
                                        <b>Card Number:</b> {CompanyDetail.cardNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>Transaction Number:</b> {CompanyDetail.trxRefNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>Payment Date:</b> {moment(CompanyDetail.paymentDate).format('MM/DD/YYYY')}
                                    </Col>
                                    <Col md={12}>
                                        <b>Subscription Start Date:</b> {moment(CompanyDetail.subscriptionStartDate).format('MM/DD/YYYY')}
                                    </Col>
                                    <Col md={12}>
                                        <b>Subscription End Date:</b> {moment(CompanyDetail.subscriptionEndDate).format('MM/DD/YYYY')}
                                    </Col>
                                </Row>
                            }
                        </Tab>
                    </Tabs>
                    <Form>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={(e) => props.setShowDetailModel(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}