import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { HiPlus, HiUserGroup } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { CheckCircle, Gear, RemoveCircle } from '../../Components/ActionBtns';
import DataGrid from '../../Components/DataGrid';
import HeaderButton from '../../Components/HeaderButton';
import { AxiosLite, CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { ActionItem, ActionsBox, CircleBtnWithIcon, ContentBox, ContentSubHeader, FormGroup, InputField, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { TiUpload } from 'react-icons/ti';

const columns = [
    { key: 'planName', name: 'Plan Name', sortable: true, searchable: true, disableSearch: false },
    { key: 'priceMonthly', name: 'Price Monthly', searchable: true, disableSearch: false },
    { key: 'priceYearly', name: 'Price Yearly', searchable: true, disableSearch: false },
    // { key: 'payPalMonthlyPlanId', name: 'PayPal Monthly Plan Id', searchable: true, disableSearch: true },
    // { key: 'payPalYearlyPlanId', name: 'PayPal Yearly Plan Id', searchable: true, disableSearch: true },
    { key: 'status', name: 'Status' },
    { key: 'actions', name: 'Actions' },
];

function SubscriptionPlansList() {
    const [SubscriptionList, setSubscriptionList] = useState([]);
    const [SelectePlanId, setSelectePlanId] = useState(0);
    const [ShowAddEditModal, setShowAddEditModal] = useState(false);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite('api/AdminPanel/Subscription/GetSubscriptionList|get')
        if (!!response && response.status === 200) {
            setSubscriptionList(response.data)
        }
    }
    const UpdateStatus = async (id) => {
        var response = await CallAxios('api/AdminPanel/Subscription/UpdateSubscriptionStatus?planId=' + id + '|get')
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const CreateSubscriptionPlanOnPaypal = async (id) => {
        var response = await CallAxios('api/AdminPanel/Subscription/CreateSubscriptionPlanOnPaypal?planId=' + id + '|get')
        if (!!response && response.status === 200) {
            getData()
        }
    }

    return (
        <>
            {
                ShowAddEditModal === true ?
                    <SubscriptionAddEditModal show={ShowAddEditModal} IdforEdit={SelectePlanId} setShowAddEditModal={setShowAddEditModal} getData={getData} />
                    : null
            }
            <ContentSubHeader>
                <SubHeaderWrapper>
                    <SubHeaderTitleBox>
                        <HiUserGroup color="rgb(1, 219, 169)" size={22} />
                        <SubHeadTitle>Subscription Plans</SubHeadTitle>
                    </SubHeaderTitleBox>
                    <ActionsBox>
                        <ActionItem>
                            <HeaderButton title="Add" onClick={() => { setSelectePlanId(0); setShowAddEditModal(true) }} icon={<HiPlus color='#fff' size={18} />} />
                        </ActionItem>
                    </ActionsBox>
                </SubHeaderWrapper>
            </ContentSubHeader>
            <ContentBox>
                <Container fluid>
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="p-4">
                                <Row>
                                    <DataGrid
                                        columns={columns}
                                        rows={SubscriptionList}

                                        customBody={(rows) =>
                                            rows?.map((val, i) =>
                                                <tr>
                                                    <td>{val.planName}</td>
                                                    <td>{val.priceMonthly}</td>
                                                    <td>{val.priceYearly}</td>
                                                    {/* <td>{val.payPalMonthlyPlanId}</td>
                                                    <td>{val.payPalYearlyPlanId}</td> */}
                                                    <td>
                                                        {val.status == Constants.SubscriptionPlansStaus.Active ?
                                                            <CheckCircle color="#00dba9" data-tooltip-id="my-tooltip" data-tooltip-content="Active" onClick={() => { UpdateStatus(val.id) }} />
                                                            :
                                                            <RemoveCircle color="#f25656" data-tooltip-id="my-tooltip" data-tooltip-content="Inactive" onClick={() => { UpdateStatus(val.id) }} />
                                                        }

                                                    </td>
                                                    <td>
                                                        <Gear color='#0d7be3' onClick={() => { setSelectePlanId(val.id); setShowAddEditModal(true) }} />
                                                        {/* {
                                                            (!val.payPalMonthlyPlanId || !val.payPalYearlyPlanId) &&
                                                            <CircleBtnWithIcon data-tooltip-id="my-tooltip" data-tooltip-content="Create billing plan on paypal" onClick={() => CreateSubscriptionPlanOnPaypal(val.id)} ><TiUpload ></TiUpload></CircleBtnWithIcon>
                                                        } */}
                                                    </td>
                                                </tr>
                                            )}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col >
                </Container >
            </ContentBox >
        </>
    );
}

export default SubscriptionPlansList;


function SubscriptionAddEditModal(props) {
    const [FeatureList, setFeatureList] = useState([]);
    const [PlanName, setPlanName] = useState("");
    const [Price, setPrice] = useState(0);
    const [PriceYearly, setPriceYearly] = useState(0);
    const [PayPalMonthlyPlanID, setPayPalMonthlyPlanID] = useState("");
    const [PayPalYearlyPlanID, setPayPalYearlyPlanID] = useState("");
    const [SetSelectedTrigger, setSetSelectedTrigger] = useState(false);
    const [SubscriptionPlanFeature, setSubscriptionPlanFeature] = useState([]);

    const [SelectedFeature, setSelectedFeature] = useState([]);
    useEffect(() => {
        getFeature()
        if (props.IdforEdit > 0) {
            getData()
        }
    }, []);
    useEffect(() => {
        if (SetSelectedTrigger) {
            let prevState = [...FeatureList];
            let Selected = [];
            debugger
            prevState.map((val, key) => {
                SubscriptionPlanFeature.map((v, i) => {
                    if (val.name === v.key) {
                        let obj = {};
                        if (val.isValueRequired === false) {
                            val.value = "true";
                            obj = { key: val.name, value: "true" }
                        } else {
                            val.value = v.value;
                            val.isDisable = false;
                            obj = { key: val.name, value: v.value }
                        }
                        Selected.push(obj);
                        // setSelectedFeature([...SelectedFeature, obj])
                        // prevState[key] = val
                    }
                })
            })
            setSelectedFeature(Selected)
            setFeatureList(prevState)
        }
    }, [SetSelectedTrigger]);
    const getFeature = async () => {
        var response = await AxiosLite('api/AdminPanel/Subscription/GetSubscriptionFeatureList|get')
        if (!!response && response.status === 200) {
            let prevData = [...response.data];
            let newarr = [];
            prevData.map(x => x.isValueRequired === true ? newarr.push({ ...x, value: "", isDisable: true }) : newarr.push(x));
            setFeatureList(newarr);
        }
    }
    const getData = async () => {
        var response = await AxiosLite('api/AdminPanel/Subscription/GetSubscriptionPlanById?planId=' + props.IdforEdit + '|get')
        if (!!response && response.status === 200) {
            debugger
            setPlanName(response.data.title)
            setPrice(response.data.priceMonthly)
            setPriceYearly(response.data.priceYearly)
            setSubscriptionPlanFeature(response.data.featureList)
            setPayPalMonthlyPlanID(response.data.payPalMonthlyPlanId)
            setPayPalYearlyPlanID(response.data.payPalYearlyPlanId)
            setSetSelectedTrigger(true)
        }
    }

    const SaveData = async () => {
        if (!PlanName) {
            toast.error("Title is required")
        } else if (!Price.toString()) {
            toast.error("Invalid price")
        } else {
            if (SelectedFeature.length > 0) {
                var findObj = SelectedFeature.find(val => !val.value || val.value === 0)
                if (!!findObj) {
                    toast.error(findObj.key + " value required.")
                    return
                }
            }
            var data = {
                PlanId: props.IdforEdit,
                Title: PlanName,
                PriceMonthly: parseFloat(Price),
                PriceYearly: parseFloat(PriceYearly),
                FeatureList: SelectedFeature,
                PayPalMonthlyPlanId: PayPalMonthlyPlanID,
                PayPalYearlyPlanId: PayPalYearlyPlanID,
            }
            var response = await CallAxios('api/AdminPanel/Subscription/AddEditPlan|post', data)
            if (!!response && response.status === 200) {
                props.getData()
                props.setShowAddEditModal(false)
            }
        }
    }

    const oNSelectFeature = (checked, values, index) => {
        let prevState = [...FeatureList];
        var mainListobj = prevState[index];
        let obj = {};
        if (checked === true) {
            if (values.isValueRequired === false) {
                obj = { key: values.name, value: checked.toString() }
            } else {
                mainListobj.isDisable = false;
                obj = { key: values.name, value: values.value.toString() }
            }
            prevState[index] = mainListobj;
            setFeatureList(prevState)
            setSelectedFeature([...SelectedFeature, obj])
        } else {
            let prevSelected = [...SelectedFeature];
            if (values.isValueRequired === true) {
                mainListobj.isDisable = true;
            }
            let newArr = prevSelected.filter(x => x.key !== values.name);
            setSelectedFeature(newArr);
        }
    }

    const onInputChange = (value, index, key) => {
        let prevSelected = [...SelectedFeature];
        let findindex = prevSelected.findIndex(x => x.key === key);
        var findobj = prevSelected[findindex];
        findobj.value = value;
        prevSelected[findindex] = findobj;
        setSelectedFeature(prevSelected)

        let prevState = [...FeatureList];
        prevState[index].value = value;
        setFeatureList(prevState)
    }

    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShowAddEditModal(false)}>
                <Modal.Header closeButton className="position-relative absoluteModalClose">
                    <Modal.Title>{props.IdforEdit === 0 ? "Add " : "Edit "} Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>Title</Form.Label>
                                    <InputField border="2" type="text" name="name" value={PlanName} onChange={(e) => setPlanName(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>Price Monthly</Form.Label>
                                    <InputField border="2" type="text" name="name" value={Price} onChange={(e) => setPrice(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>Price Yearly</Form.Label>
                                    <InputField border="2" type="text" name="name" value={PriceYearly} onChange={(e) => setPriceYearly(e.target.value)} />
                                </FormGroup>
                            </Col>
                            {/* <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>PayPal Monthly Plan Id</Form.Label>
                                    <InputField border="2" type="text" name="name" value={PayPalMonthlyPlanID} onChange={(e) => setPayPalMonthlyPlanID(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>PayPal Yearly Plan Id</Form.Label>
                                    <InputField border="2" type="text" name="name" value={PayPalYearlyPlanID} onChange={(e) => setPayPalYearlyPlanID(e.target.value)} />
                                </FormGroup>
                            </Col> */}
                            <Col md={12}>
                                <FormGroup className="position-relative mb-3">
                                    <Form.Label className='fw-bold'>Features</Form.Label>
                                </FormGroup>
                            </Col>

                            {
                                FeatureList.map((val, i) => {
                                    return <>
                                        <Col md={12}>
                                            <FormGroup className="position-relative mb-3">
                                                <Form.Check onChange={(e) => oNSelectFeature(e.target.checked, val, i)} checked={SelectedFeature.some(x => x.key === val.name) ? true : false} type="checkbox" value={val.name} label={val.name} name={val.name} id={i} className="ThickBorder my-0 pr40" />
                                            </FormGroup>
                                            {
                                                val.isValueRequired &&
                                                <FormGroup className="position-relative mb-3">
                                                    <InputField border="2" type="number" name="name" value={val.value} onChange={(e) => onInputChange(e.target.value, i, val.name)} disabled={val.isDisable} />
                                                </FormGroup>
                                            }
                                        </Col>

                                    </>
                                })
                            }

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={(e) => props.setShowAddEditModal(false)}>Close</Button>
                    <Button className="btn btn-secondary" onClick={(e) => SaveData()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}