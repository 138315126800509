import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { ThemeProvider } from 'styled-components';
import { Forbidden401 } from '../Components/Forbidden401';
import { Forbidden403 } from '../Components/Forbidden403';
import Loader from '../Components/Loader';
import PageNotFound from '../Components/PageNotFound';
import { SignInManager } from '../Helpers/Authentication/SignInManager';
import { Constants } from '../Helpers/Contents';
import { HooksHelper } from '../Helpers/HooksHelper';
import { UpdateReduxStates } from '../Helpers/MasterData';
import { Mainlayout } from '../Layout/Mainlayout';
import Invites from '../Pages/Accounts/Invites';
import { Login } from '../Pages/Accounts/Login';
import { ResetPassword } from '../Pages/Accounts/ResetPassword';
import CompaniesList from '../Pages/Companies/CompaniesList';
import Employees from '../Pages/Employee/Employees';
import Locations from '../Pages/Location/Locations';
import SubscriptionPlansList from '../Pages/SubscriptionPlans/SubscriptionPlansList';
import SupportUserList from '../Pages/SupportUser/SupportUserList';
import TicketsList from '../Pages/Tickets/TicketsList';
import './App.css';
import FolderStructure from '../Pages/FolderStructure/FolderStructure';

export const App = () => {
  const curLoc = useLocation();
  const user = useSelector(x => x.app.user);

  const titlePrefix = ' | Basta-Shift';
  const jwt = useSelector(s => s.app.jwt);
  const themeColor = useSelector(state => state.app.theme);

  const dispatch = useDispatch();
  HooksHelper.navigate = useNavigate();
  HooksHelper.dispatch = dispatch;
  useEffect(() => {
    if (!!jwt.token && jwt.token !== ".") {
      UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
    }
  }, [jwt.token]);




  const titleMap = [
    { path: '/', title: 'Site' },
    { path: '/login', title: 'Login' },
    { path: '/tickets', title: 'Tickets' },
    { path: '/supportUsers', title: 'Support Users' },
  ];
  useEffect(() => {
    const curTitle = titleMap.find(item => item.path.startsWith(curLoc.pathname.toLowerCase()))
    if (curTitle && curTitle.title) {
      document.title = curTitle.title + titlePrefix
    } else {
      document.title = 'Default' + titlePrefix
    }
  }, [curLoc, titleMap])
  const isAuthenticated = new SignInManager().IsAuthenticated;

  return (
    <div className="App  add100">
      <ThemeProvider theme={themeColor}>
        <Loader />
        <ToastContainer limit={2} newestOnTop theme='light' />
        <Tooltip id="my-tooltip" />
        <Tooltip id="my-tooltip-click" openOnClick={true} />
        <Routes>
          {
            isAuthenticated ?
              <Route element={<Mainlayout />}>
                <Route path='/' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><CompaniesList /></ProtectedRoute>} />
                <Route path='/companies' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><CompaniesList /></ProtectedRoute>} />
                <Route path='/company/:companyid/locations' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><Locations /></ProtectedRoute>} />
                <Route path='/company/:companyid/locations/:locationid/employees' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><Employees /></ProtectedRoute>} />
                <Route path='subcriptionplans' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><SubscriptionPlansList /></ProtectedRoute>} />
                <Route path='tickets' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><TicketsList /></ProtectedRoute>} />
                <Route path='supportUsers' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><SupportUserList /></ProtectedRoute>} />
                {/* <Route path='folder_structure' element={<ProtectedRoute role={[Constants.roles.SuperAdmin]}><FolderStructure /></ProtectedRoute>} /> */}
              </Route>
              :
              <Route>
                <Route path='/' element={<ProtectedRoute><EmptyRoute /></ProtectedRoute>}> </Route>
              </Route>

          }

          {/* <Route path='/' element={<CheckAuthentication><Login /></CheckAuthentication>} /> */}
          <Route path='/login' element={<CheckAuthentication><Login /></CheckAuthentication>} />
          <Route path='/Invites' element={<Invites />} />
          <Route path='/Passwordreset' element={<ResetPassword />} />

          <Route path="/forbidden401" element={<Forbidden401 />} />
          <Route path="/forbidden403" element={<Forbidden403 />} />
          <Route path="*" element={<PageNotFound />} />

        </Routes>

      </ThemeProvider>
    </div >
  );
}
function EmptyRoute() {
  return <></>
}
function CheckAuthentication(props) {
  const { children } = props;
  var navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const ParseJwt = new SignInManager().ParseJwt;
  if (isAuthenticated) {
    UpdateReduxStates(ParseJwt, dispatch)
    navigate(`/companies`)

    // navigate(`/companies/${ParseJwt.CompanyId}/calendar`);
  } else {
    return children;
  }
}

export function ProtectedRoute(props) {
  const { children } = props;
  const location = useLocation();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const role = new SignInManager().UserRole;
  if (!isAuthenticated || (props.role && role && !props.role.some(x => role.split(",").includes(x)))) {
    const rUri = location.pathname;
    return <Navigate to={'/login?redirect_url=' + rUri} replace />;
  }
  return children;
};
