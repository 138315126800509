import React from 'react';
import { ButtonBox, HeaderButtonIcon, HeaderButtonText } from '../Helpers/Global/StyledComponents';

function HeaderButton(props) {
  const { title, icon, btnLight, onClick } = props;
  return (
    <>
      <ButtonBox onClick={onClick} Light={btnLight}>
        {!!icon ?
          <HeaderButtonIcon>{icon}</HeaderButtonIcon>
          : null
        }
        <HeaderButtonText Light={btnLight}>{title}</HeaderButtonText>
      </ButtonBox>
    </>
  );
}

export default HeaderButton;
