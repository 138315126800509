import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { AvatarBox, CollapseMenu, DisplayBetween, HeaderLeftLabel, HeaderMenu, HeaderMenuItem } from '../../Helpers/Global/StyledComponents';




function MainHeader(props) {
    const { isCollapse, setIsCollapse } = props;
    const user = useSelector(s => s.app.user);
    const navigate = useNavigate();

    return (
        <>
            <DisplayBetween>
                <HeaderLeftLabel>
                    <CollapseMenu className='me-3' onClick={() => setIsCollapse(!isCollapse)}>
                        {isCollapse ?
                            <BsChevronDoubleRight size={20} />
                            :
                            <BsChevronDoubleLeft size={20} />
                        }

                    </CollapseMenu>
                    Human Resource Management System
                </HeaderLeftLabel>
                <HeaderMenu>
                    <HeaderMenuItem>
                        <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={<UserAvatar Image="" Username={user.empName} />}
                        >
                            {/* <NavDropdown.Divider /> */}
                            <NavDropdown.Item href="" onClick={(e) => { e.preventDefault(); new SignInManager().Logout(navigate) }}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </HeaderMenuItem>
                </HeaderMenu>
            </DisplayBetween>
        </>
    );
}

export default MainHeader;


const UserAvatar = (props) => {
    const { Image, Username } = props;
    const UsernameShort = Username.split(' ').map(word => word.charAt(0).toUpperCase())
    return (
        <>
            <AvatarBox bgImage={Image}>{!!Image ? null : UsernameShort}</AvatarBox> {Username}
        </>
    )
}
